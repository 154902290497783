@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}
.gold {
  color: #f5bf06;
}
@layer components {
  .hamburger div:first-child {
    @apply rotate-45 translate-y-1.5;
  }
  .hamburger div:last-child {
    @apply -rotate-45 -translate-y-1;
  }
}

.sticky_header{
  position: sticky !important;
  z-index: 50;
  top:0;
}
.nav {
  background-color: #101315;
}
.brown {
  background-color: #460a0b;
}
.orange {
  background-color: #ffa726;
}

.contact-banner {
  background-image: url(../src/assets/Contact/mateusz-suski-MnayUWBXurI-unsplash.jpg);
  background-position: center;
  background-size: cover;
  height: 255px;
}
.about-middle-banner {
  background-image: url(../src/assets/Contact/mateusz-suski-MnayUWBXurI-unsplash.jpg);
  background-position: center;
  background-size: cover;
}
.testimonial-banner {
  background-image: url(../src/assets/Contact/mateusz-suski-MnayUWBXurI-unsplash.jpg);
  background-position: center;
  background-size: cover;
  height: 384px;
}
.copper-ore-banner {
  background-image: url(../src/assets/Contact/copper.jpg);
  background-position: center;
  background-size: cover;
  height: 255px;
}
.electric-car-banner {
  background-image: url(../src/assets/Contact/ecar2.jpg);
  background-position: center;
  background-size: cover;
  height: 255px;
}

.lists {
  color: black;
  width: 38%;
  height: 0;
  border-bottom: 350px solid #df530e;
  border-left: 75px solid transparent;
}
.lists_2 {
  border: solid #b4530a;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

/* ----------------------gallery--------- */

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  cursor: pointer;
}

/* --------whats up--------------- */

 .whatsapp-chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: #fff;
    padding: 10px 20px;
    border-radius: 50%;
    z-index: 9999;
  } 


.whatsapp-chat {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.whatsapp-icon {
  margin-top: 16px;
}

@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp-chat {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
